import { useTotalAbvThroughput } from "./useTotalAbvThroughput"
import { useTotalBlxStyleThroughput } from "./useTotalBlxStyleThroughput"
import { useTotalPlatformThroughput } from "./useTotalPlatformThroughput"
import { useTotalPlatformThroughputSummary } from "./useTotalPlatformThroughputSummary"
import { useTotalProductThroughput } from "./useTotalProductThroughput"
import { useTotalStyleThroughput } from "./useTotalStyleThroughput"
import { useTotalTapThroughput } from "./useTotalTapThroughput"




export {
    useTotalAbvThroughput,
    useTotalBlxStyleThroughput,
    useTotalPlatformThroughput,
    useTotalPlatformThroughputSummary,
    useTotalProductThroughput,
    useTotalStyleThroughput,
    useTotalTapThroughput

}