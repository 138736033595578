import { gql } from 'graphql-request'

/**
 * GraphQL query to fetch total throughput grouped by BLX Style.
 * 
 * @param input - The input parameters for the query.
 * @returns The total throughput grouped by BLX Style.
 */
export const getTotalBlxStyleThroughputQuery = gql`
  query GetTotalBlxStyleThroughput($input: inputGetTotalThroughput!) {
    getTotalBlxStyleThroughput(input: $input) {
      throughput {
        avgCost
        blxGroup
        costDepleted
        endDate
        performacePlatformId
        productCount
        startDate
        throughput
      }
    }
  }
`
