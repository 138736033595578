import type { SerializedError } from "@reduxjs/toolkit"
import { skipToken } from "@reduxjs/toolkit/query"
import type { GraphQLError } from "graphql"
import type { TotalPlatformThroughput } from "types"
import { selectActivePpSubId } from "../../../features/orgGroups/state/orgGroupSelectors"
import { useAppSelector } from "../../../state/store"
import { useGetTotalPlatformThroughputQuery } from "../totalThroughputApi"

/**
 * Generates a token object containing a platform subscription ID and a date range.
 *
 * @param ppSubId - The platform subscription ID.
 * @param start - The start date of the date range.
 * @param end - The end date of the date range.
 * @returns An object containing the platform subscription ID and the date range with start and end dates in ISO string format, or undefined if any of the parameters are invalid.
 */
const token = (ppSubId: string, start: Date, end: Date): { ppSubId: string, dateRange: { startDate: string, endDate: string } } | undefined => {
    if (!ppSubId || !start || !end) return undefined
    return {
        ppSubId,
        dateRange: {
            startDate: start.toISOString(),
            endDate: end.toISOString()
        }
    }
}

/**
 * Custom hook to fetch total throughput grouped by depletionDate (Day).
 * @param startDate - The start date of the date range.
 * @param endDate - The end date of the date range.
 * @returns An object containing the total platform throughput data, any error encountered, 
 *          and flags indicating the query status.
 */
export const useTotalPlatformThroughput = (
    startDate: Date,
    endDate: Date
): {
    totalPlatformThroughput: TotalPlatformThroughput | undefined,
    totalPlatformThroughputError: GraphQLError | SerializedError | undefined,
    isLoadingTotalPlatformThroughput: boolean,
    isSuccessTotalPlatformThroughput: boolean,
    isFetchingTotalPlatformThroughput: boolean,
    isErrorTotalPlatformThroughput: boolean
} => {
    const ppSubId = useAppSelector(selectActivePpSubId)

    const { data, error, isSuccess, isFetching, isLoading, isError } = useGetTotalPlatformThroughputQuery(token(ppSubId, startDate, endDate) ?? skipToken)
    return {
        totalPlatformThroughput: data,
        totalPlatformThroughputError: error,
        isLoadingTotalPlatformThroughput: isLoading,
        isSuccessTotalPlatformThroughput: isSuccess,
        isFetchingTotalPlatformThroughput: isFetching,
        isErrorTotalPlatformThroughput: isError
    }
}
