import { gql } from 'graphql-request'

/**
 * GraphQL query to fetch total throughput grouped by Style.
 * 
 * @param input - The input parameters for the query.
 * @returns The total throughput grouped by Style.
 */
export const getTotalStyleThroughputQuery = gql`
  query GetTotalStyleThroughput($input: inputGetTotalThroughput!) {
    getTotalStyleThroughput(input: $input) {
      throughput {
        avgCost
        costDepleted
        endDate
        performacePlatformId
        productCount
        startDate
        styleLabel
        styleTitle
        throughput
      }
    }
  }
`