import { gql } from 'graphql-request'

/**
 * GraphQL query to fetch total platform throughput summary.
 * 
 * @param input - The input parameters for the query.
 * @returns The total platform throughput summary.
 */
export const getTotalPlatformThroughputSummaryQuery = gql`
  query GetTotalPlatformThroughputSummary($input: inputGetTotalThroughput!) {
    getTotalPlatformThroughputSummary(input: $input) {
      topBrand
      topProduct
      topStyle
      topStyleLabel
      totalThroughput
    }
  }
`