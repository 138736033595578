import { gql } from 'graphql-request'

/**
 * GraphQL query to fetch total throughput grouped by ABV.
 * 
 * @param input - The input parameters for the query.
 * @returns The total throughput grouped by ABV.
 */
export const getTotalAbvThroughputQuery = gql`
  query GetTotalAbvThroughput($input: inputGetTotalThroughput!) {
    getTotalAbvThroughput(input: $input) {
      throughput {
        abvGroup
        avgCost
        costDepleted
        endDate
        performacePlatformId
        productCount
        startDate
        throughput
        topStyle
      }
    }
  }
`
