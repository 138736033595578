"use client"

import { skipToken } from "@reduxjs/toolkit/query"
import type { VarianceReportDetail, VarianceReportMetadataDetail } from "types"
import { useGetVarianceReportTotalsQuery } from "../api/varianceReportsApi"

const token = (ppSubId, start: Date, end: Date): { ppSubId: string, dateRange: { startDate: string, endDate: string } } | undefined => {
    if (ppSubId && start && end) {
        const startDate = start.toISOString()
        const endDate = end.toISOString()

        if (ppSubId.length > 0 && startDate.length > 0 && endDate.length > 0) {
            return {
                ppSubId, dateRange: { startDate, endDate }
            }
        } else return undefined
    }

    return undefined
}

/**
 * Custom hook to fetch variance reports.
 * @param ppSubId - The performance platform subscription ID.
 * @returns A function that takes startDate and endDate to fetch the data.
 */
export const useVarianceReports = (ppSubId) => (startDate: Date, endDate: Date) => {
    const { data, error, isSuccess, isFetching } = useGetVarianceReportTotalsQuery(token(ppSubId, startDate, endDate) ?? skipToken)

    const varianceReportDetails: VarianceReportDetail[] = data?.variance || []
    const varianceReportMetadataDetail: VarianceReportMetadataDetail | undefined = data?.metadata

    return { varianceReportDetails, varianceReportMetadataDetail, error, isSuccess, isFetching }
}