/**
 * Hook to fetch throughput data for a Performance Platform subscription.
 * Provides both detailed and summary throughput data based on the specified mode.
 *
 * @param options - Configuration options for the hook
 * @param {DateTime} [options.date] - Optional specific date to fetch data for
 * @param {number} [options.days=7] - Number of days to fetch data for (default: 7)
 * @param {Date[]} [options.dateRange] - Optional date range array [startDate, endDate]
 * @param {"details" | "summary"} [options.mode] - Mode of data to fetch ("details" or "summary")
 * 
 * @returns {Object} Object containing throughput data and loading states
 * @returns {Object} throughputDetails - Detailed throughput data when mode is "details" or undefined
 * @returns {boolean} isLoadingDetails - Loading state for details data
 * @returns {boolean} isFetchingDetails - Fetching state for details data
 * @returns {boolean} isErrorDetails - Error state for details data
 * @returns {Object} throughputSummary - Summary throughput data when mode is "summary" or undefined
 * @returns {boolean} isLoadingSummary - Loading state for summary data
 * @returns {boolean} isFetchingSummary - Fetching state for summary data
 * @returns {boolean} isErrorSummary - Error state for summary data
 * @returns {Function} refetch - Function to manually refetch the data
 */

"use client"
import { DateTime } from "luxon"
import { type InputGetThroughputByPpSub, TimeFrame, TimeZone } from "types"
import { TimeMapping, getBeginingOfDayTimestamp, getEndOfDayTimestamp } from "utilities"
import {
    useGetTotalPlatformThroughputQuery
} from "../../../api"
import { useGetTotalPlatformThroughputSummaryQuery } from "../../../api/totalThroughput/totalThroughputApi"
import { getAPriorDate } from "../../../utilities/getAPriorDate"
import { usePPSub } from "../../ppSubscription"
import { useBrewlogixUser } from "../../user"

export const useThroughputByPpSub = ({
    date,
    days = 7,
    dateRange,
    mode
}: {
    date?: DateTime
    days?: number
    dateRange?: Date[]
    mode?: "details" | "summary"
}) => {
    const { userData } = useBrewlogixUser()
    const { ppSubApiData } = usePPSub()
    const userTimeZone =
        TimeMapping[ppSubApiData?.settings?.timeZone] || TimeMapping[TimeZone.UsEastern]
    let startDate
    let endDate

    if (dateRange && dateRange[0] && dateRange[1]) {
        startDate = getBeginingOfDayTimestamp(
            DateTime.fromJSDate(dateRange[0]).setZone(userTimeZone).toString()
        )
        endDate = getEndOfDayTimestamp(
            DateTime.fromJSDate(dateRange[1]).setZone(userTimeZone).toString()
        )
    } else {
        const selectedDate: DateTime = date
            ? date
            : getAPriorDate({
                number: 1,
                timezone: ppSubApiData?.settings?.timeZone
            })

        const daysBeforeSelectedDate: string = selectedDate?.minus({ days: days - 1 }).toString()

        startDate = getBeginingOfDayTimestamp(daysBeforeSelectedDate)
        endDate = getEndOfDayTimestamp(selectedDate?.toString())
    }

    const inputData: InputGetThroughputByPpSub = {
        ppSubId: ppSubApiData?.id,
        dateRange: {
            startDate,
            endDate
        },
        sortAscending: false,
        timeFrame: TimeFrame.Day
    }

    const {
        data: throughputDetails,
        isLoading: isLoadingDetails,
        isFetching: isFetchingDetails,
        isError: isErrorDetails,
        refetch: refetchDetails
    } = useGetTotalPlatformThroughputQuery(inputData, {
        skip:
            !userData?.id ||
            !ppSubApiData?.id ||
            (dateRange && (!dateRange[0] || !dateRange[1])) ||
            mode === "summary"
    })

    const {
        data: throughputSummary,
        isLoading: isLoadingSummary,
        isFetching: isFetchingSummary,
        isError: isErrorSummary,
        refetch: refetchSummary
    } = useGetTotalPlatformThroughputSummaryQuery(inputData, {
        skip:
            !userData?.id ||
            !ppSubApiData?.id ||
            (dateRange && (!dateRange[0] || !dateRange[1])) ||
            mode === "details"
    })

    return {
        throughputDetails,
        isLoadingDetails,
        isFetchingDetails,
        isErrorDetails,
        throughputSummary,
        isLoadingSummary,
        isFetchingSummary,
        isErrorSummary,
        refetch: () => {
            if (mode !== "summary") refetchDetails()
            if (mode !== "details") refetchSummary()
        }
    }
}
