import type { InputGetTotalThroughput, TotalAbvThroughput, TotalBlxStyleThroughput, TotalPlatformThroughput, TotalPlatformThroughputSummary, TotalProductThroughput, TotalStyleThroughput, TotalTapThroughput, VarianceReportTotals } from 'types'
import { getTotalPlatformThroughputQuery } from '../../features/throughput/queries/getTotalPlatformThroughputQuery'
import { getTotalStyleThroughputQuery } from '../../features/throughput/queries/getTotalStyleThroughputQuery'
import { getVarianceReportQuery } from '../../queries/private/varianceReport'
import { baseApi } from '../baseApi'
import { getTotalAbvThroughputQuery } from './queries/getTotalAbvThroughputQuery'
import { getTotalBlxStyleThroughputQuery } from './queries/getTotalBlxStyleThroughputQuery'
import { getTotalPlatformThroughputSummaryQuery } from './queries/getTotalPlatformThroughputSummaryQuery'
import { getTotalProductThroughputQuery } from './queries/getTotalProductThroughputQuery'
import { getTotalTapThroughputQuery } from './queries/getTotalTapThroughputQuery'

const totalThroughputApi = baseApi.injectEndpoints({
    overrideExisting: true,
    endpoints: (build) => ({
        /**
         * Fetches total throughput grouped by ABV.
         * @param input - The input parameters for the query.
         */
        getTotalAbvThroughput: build.query<TotalAbvThroughput, InputGetTotalThroughput>({
            query: (input) => ({
                query: getTotalAbvThroughputQuery,
                args: input,
            }),
            providesTags: ['TotalAbvThroughput'],
        }),
        /**
         * Fetches total throughput grouped by BLX Style.
         * @param input - The input parameters for the query.
         */
        getTotalBlxStyleThroughput: build.query<TotalBlxStyleThroughput, InputGetTotalThroughput>({
            query: (input) => ({
                query: getTotalBlxStyleThroughputQuery,
                args: input,
            }),
            providesTags: ['TotalBlxStyleThroughput'],
        }),
        /**
         * Fetches total throughput grouped by depletionDate (Day).
         * @param input - The input parameters for the query.
         */
        getTotalPlatformThroughput: build.query<TotalPlatformThroughput, InputGetTotalThroughput>({
            query: (input) => ({
                query: getTotalPlatformThroughputQuery,
                args: input,
            }),
            providesTags: ['TotalPlatformThroughput'],
        }),
        /**
         * Returns highlights i.e topBrand, topStyle, topProduct totalThroughput.
         * @param input - The input parameters for the query.
         */
        getTotalPlatformThroughputSummary: build.query<TotalPlatformThroughputSummary, InputGetTotalThroughput>({
            query: (input) => ({
                query: getTotalPlatformThroughputSummaryQuery,
                args: input,
            }),
            providesTags: ['TotalPlatformThroughputSummary'],
        }),
        /**
         * Fetches total throughput grouped by Product.
         * @param input - The input parameters for the query.
         */
        getTotalProductThroughput: build.query<TotalProductThroughput, InputGetTotalThroughput>({
            query: (input) => ({
                query: getTotalProductThroughputQuery,
                args: input,
            }),
            providesTags: ['TotalProductThroughput'],
        }),
        /**
         * Fetches total throughput grouped by Style.
         * @param input - The input parameters for the query.
         */
        getTotalStyleThroughput: build.query<TotalStyleThroughput, InputGetTotalThroughput>({
            query: (input) => ({
                query: getTotalStyleThroughputQuery,
                args: input,
            }),
            providesTags: ['TotalStyleThroughput'],
        }),
        /**
         * Fetches total throughput grouped by Tap.
         * @param input - The input parameters for the query.
         */
        getTotalTapThroughput: build.query<TotalTapThroughput, InputGetTotalThroughput>({
            query: (input) => ({
                query: getTotalTapThroughputQuery,
                args: input,
            }),
            providesTags: ['TotalTapThroughput'],
        }),
        /**
         * Uses prepared query in DL to return Variance Report totals.
         * @param input - The input parameters for the query.
         */
        getVarianceReport: build.query<VarianceReportTotals, InputGetTotalThroughput>({
            query: (input) => ({
                query: getVarianceReportQuery,
                args: input,
            }),
            providesTags: ['VarianceReport'],
        }),
    }),
})

const {
    useGetTotalAbvThroughputQuery,
    useGetTotalBlxStyleThroughputQuery,
    useGetTotalPlatformThroughputQuery,
    useGetTotalPlatformThroughputSummaryQuery,
    useGetTotalProductThroughputQuery,
    useGetTotalStyleThroughputQuery,
    useGetTotalTapThroughputQuery,
    useGetVarianceReportQuery,
} = totalThroughputApi

export {
    useGetTotalAbvThroughputQuery,
    useGetTotalBlxStyleThroughputQuery,
    useGetTotalPlatformThroughputQuery,
    useGetTotalPlatformThroughputSummaryQuery,
    useGetTotalProductThroughputQuery,
    useGetTotalStyleThroughputQuery,
    useGetTotalTapThroughputQuery,
    useGetVarianceReportQuery,
    totalThroughputApi
}