/**
 * Hook to fetch throughput data organized by product for a specific date range.
 * Uses the Performance Platform subscription context to fetch data.
 *
 * @param {Date[]} [dateRange] - Optional array containing [startDate, endDate] for the data range
 * 
 * @returns {Object} Object containing throughput data and loading states
 * @returns {Object} throughputByProductHookData - The fetched throughput data organized by product
 * @returns {boolean} isFetchingThroughputByProduct - Whether the data is currently being fetched
 * @returns {Function} refetchThroughputByProduct - Function to manually refetch the data
 * @returns {boolean} isLoadingThroughputByProduct - Whether the initial data load is in progress
 * @returns {boolean} isErrorThroughputByProduct - Whether there was an error fetching the data
 */

"use client"
import { type InputGetThroughputByProduct, TimeFrame, TimeZone } from "types"
import { useGetThroughputByProductQuery } from "../../../api"
import getFormattedStartAndEndDateStringsFromADateRange from "../../../utilities/getFormattedStartAndEndDateStringsFromADateRange"
import { usePPSub } from "../../ppSubscription"

export const useThroughputByProductAndDate = (dateRange?: Date[]) => {
    const { ppSubId, ppSubApiData } = usePPSub()
    const skip = (dateRange && (!dateRange[0] || !dateRange[1])) || !ppSubId

    const { startDate, endDate } = getFormattedStartAndEndDateStringsFromADateRange({
        dateRange,
        timezone: ppSubApiData?.settings?.timeZone
    })

    const inputData: InputGetThroughputByProduct = {
        ppSubId,
        dateRange: {
            startDate,
            endDate
        },
        sortAscending: false,
        timeFrame: TimeFrame.Day,
        timeZone: ppSubApiData?.settings?.timeZone || TimeZone.UsEastern
    }

    const {
        data: throughputByProductHookData,
        isFetching: isFetchingThroughputByProduct,
        isLoading: isLoadingThroughputByProduct,
        isError: isErrorThroughputByProduct,
        refetch: refetchThroughputByProduct
    } = useGetThroughputByProductQuery(inputData, {
        skip
    })

    return {
        throughputByProductHookData,
        isFetchingThroughputByProduct,
        refetchThroughputByProduct,
        isLoadingThroughputByProduct,
        isErrorThroughputByProduct
    }
}
