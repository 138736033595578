
import type { SerializedError } from "@reduxjs/toolkit"
import type { GraphQLError } from "graphql"
import type { TotalPlatformThroughputSummary } from "types"
import { selectActivePpSubId } from "../../../features/orgGroups/state/orgGroupSelectors"
import { useAppSelector } from "../../../state/store"
import { useGetTotalPlatformThroughputSummaryQuery } from "../totalThroughputApi"

/**
 * Generates a token object for querying total platform throughput summary.
 *
 * @param ppSubId - The platform subscription ID.
 * @param start - The start date of the date range.
 * @param end - The end date of the date range.
 * @returns A token object containing the platform subscription ID and date range, or undefined if inputs are invalid.
 */
const token = (ppSubId: string, start: Date, end: Date): { ppSubId: string, dateRange: { startDate: string, endDate: string } } | undefined => {
    if (!ppSubId || !start || !end) return undefined
    return {
        ppSubId,
        dateRange: {
            startDate: start.toISOString(),
            endDate: end.toISOString()
        }
    }
}



/**
 * Custom hook to fetch the total platform throughput summary for a given date range.
 *
 * @param startDate - The start date of the date range.
 * @param endDate - The end date of the date range.
 * @returns An object containing the total platform throughput summary data, any error encountered, 
 *          and flags indicating the query status.
 */
export const useTotalPlatformThroughputSummary = (
    startDate: Date,
    endDate: Date
): {
    totalPlatformThroughputSummary: TotalPlatformThroughputSummary | undefined,
    totalPlatformThroughputSummaryError: GraphQLError | SerializedError | undefined,
    isLoadingTotalPlatformThroughputSummary: boolean,
    isSuccessTotalPlatformThroughputSummary: boolean,
    isFetchingTotalPlatformThroughputSummary: boolean
    isErrorTotalPlatformThroughputSummary: boolean
} => {
    const ppSubId = useAppSelector(selectActivePpSubId)

    const { data, error, isSuccess, isFetching, isLoading, isError } = useGetTotalPlatformThroughputSummaryQuery(token(ppSubId, startDate, endDate)!)
    return {
        totalPlatformThroughputSummary: data,
        totalPlatformThroughputSummaryError: error,
        isLoadingTotalPlatformThroughputSummary: isLoading,
        isSuccessTotalPlatformThroughputSummary: isSuccess,
        isFetchingTotalPlatformThroughputSummary: isFetching,
        isErrorTotalPlatformThroughputSummary: isError
    }
}

