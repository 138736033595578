import { gql } from 'graphql-request'

/**
 * GraphQL query to fetch total throughput grouped by Tap.
 * 
 * @param input - The input parameters for the query.
 * @returns The total throughput grouped by Tap.
 */
export const getTotalTapThroughputQuery = gql`
  query GetTotalTapThroughput($input: inputGetTotalThroughput!) {
    getTotalTapThroughput(input: $input) {
      throughput {
        avgCost
        costDepleted
        endDate
        performacePlatformId
        productCount
        startDate
        tapNumber
        tapsensorid
        throughput
      }
    }
  }
`