import { gql } from 'graphql-request'

/**
 * GraphQL query to fetch Variance Report totals.
 * 
 * @param input - The input parameters for the query.
 * @returns The Variance Report totals.
 */
export const getVarianceReportQuery = gql`
  query GetVarianceReport($input: inputGetTotalThroughput!) {
    getVarianceReport(input: $input) {
      metadata {
        totalYield
        total_depleted
        total_rev_variance
        total_sold
        total_variance
      }
      variance {
        blx_product
        pos_product_matched
        rev_variance
        total_depleted
        total_sold
        variance
      }
    }
  }
`
