import { gql } from 'graphql-request'

/**
 * GraphQL query to fetch total platform throughput.
 * 
 * @param input - The input parameters for the query.
 * @returns The total platform throughput.
 */
export const getTotalPlatformThroughputQuery = gql`
  query GetTotalPlatformThroughput($input: inputGetTotalThroughput!) {
    getTotalPlatformThroughput(input: $input) {
      throughput {
        avgCost
        costDepleted
        endDate
        performacePlatformId
        productCount
        startDate
        throughput
      }
    }
  }
`