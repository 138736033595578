import { gql } from 'graphql-request'

/**
 * GraphQL query to fetch total throughput grouped by Product.
 * 
 * @param input - The input parameters for the query.
 * @returns The total throughput grouped by Product.
 */
export const getTotalProductThroughputQuery = gql`
  query GetTotalProductThroughput($input: inputGetTotalThroughput!) {
    getTotalProductThroughput(input: $input) {
      throughput {
        abv
        avgCost
        brandTitle
        costDepleted
        endDate
        performacePlatformId
        productId
        productTitle
        productType
        startDate
        styleLabel
        styleTitle
        throughput
      }
    }
  }
`